.for_search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.assign_profile_title {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 400;
}

.not_assigned {
    margin-left: 16px;
}

.jellie_id {
    font-weight: 400;
    font-size: 16px;
}

.generate_id_modal .modal-content {
    height: 355px;
}

.Collapsible__trigger {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    padding-bottom: 10px;
    position: relative;
}

.Collapsible__trigger .down_arrow {
    transition: 0.3s;
}

.Collapsible__trigger.is-open .down_arrow {
    transform: rotate(180deg);
}

.down_arrow {
    position: absolute;
    right: 25px;
}

.collapse_name {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
    margin: 0px;
    margin-left: 10px;
    user-select: none;
}

.collapse_inner_content {
    padding-left: 50px;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 20px;
    padding-right: 20px;
}

.last_collapse {
    border: none !important;
}

.pet_profile_page {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding-bottom: 10px;
}

.pet_details {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 15px;
}

.pet_details_image {
    height: 140px;
    max-width: 140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
}

.pet_details_image img {
    width: 100%;
}

.pet_details_name {
    width: 100%;
    margin-left: 20px;
}

.pet_details_name_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.pet_details_name .pet_name {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: #000000;
    margin: 0px;
    width: 150px !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.pet_details_name .pet_sex {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
    display: block;
    padding-bottom: 10px;
}

.pet_details_name .pet_age {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* display: flex; */
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
}

.pet_details_name .pet_age .human {
    color: #9b9b9b !important;
}

.enjoy_long_hunt {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
    height: 40px;
    border: 1px solid #E5E5E5;
    border-right: none;
    border-left: none;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #616161;
}

.profile_qr {
    padding: 2px;
    height: 50px;
    width: 50px;
    border-radius: 7px;
    border: 1px solid #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_qr img {
    width: 100%;
}

.profile_collapse_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3px 0;
}

.profile_collapse_row_title {
    font-size: 14px;
    display: flex;
    font-weight: 500;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
    width: 100px;

}

.profile_collapse_row_genetics {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0;
}

.profile_collapse_row_title_genetics {
    font-size: 14px;
    /* display: flex; */
    font-weight: 500;
    /* align-items: center; */
    letter-spacing: 0.02em;
    color: #000000;
    width: 75px;
    text-align: right;
}

.profile_collapse_row_content {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #616161;
}

.profile_collapse_row_document {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #4674C1;
}

.profile_collapse_row_you {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #616161;
    text-transform: uppercase;
}

.routine_image_row {
    padding: 10px 0;
}

.routine_image_row_title {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
}

.routine_image_row li {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #616161;
    padding: 2px 0;
}

.be_host {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #E5E5E5;
    border-right: none;
    border-left: none;
    font-size: 16px;
    line-height: 0px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
    letter-spacing: 0.02em;
    margin-top: 30px;
    background-color: #fff;
}

.be_host_icon {
    margin-right: 15px;
}

.pet_profile {
    padding: 20px !important;
}

.home_header {
    width: 100%;
    text-align: center;
}

.home_header .back {
    width: 35px;
    float: left;
}

.home_header .logo {
    width: 300px;
}

.missing_pet_text {
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 1px;
    margin-top: 3px;
}

@media only screen and (max-width: 600px) {
    .pet_details_name .pet_age {
        align-items: flex-start;
        flex-direction: column;
    }

    .pet_profile {
        padding: 10px !important;
    }

    .be_host {
        margin-top: 15px;
    }

    .home_header {
        width: 100%;
        text-align: center;
    }

    .home_header .back {
        width: 25px;
        float: left;
    }

    .home_header .logo {
        width: 200px;
    }

    .pet_details_image {
        height: 100px;
        max-width: 100px;
        /* width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 50%; */
    }

    /* .pet_details_image img {
        width: 100%;
    } */

    .genetics_wrapping {
        width: 100px !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
    }
    
}