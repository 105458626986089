.alert-list {
    position: fixed;
    right: 0px;
    top: 20px;
    width: 160px;
    z-index: 1051;
}

.text_wrapping {
    width: 80px !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    margin-top: 5px !important;
}