.header_psm {
  /* display: flex;
  align-items: center;
  justify-content: start; */
  width: 100%;
}

.header_psm .backIcon {
  cursor: pointer;
  width: 50px;
}

.header_psm .pet_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

.header_psm h3 {
  font-size: 22px;
  font-weight: bold;
  display: inline;
}

.para {
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #616161 !important;
}

.box {
  /* position: relative;
  top: 200px;
  margin: 10px; */
}

.box_row {
  background: #F5813C;
  border: 0.5px solid #F5813C;
  box-sizing: border-box;
  border-radius: 10px;
  height: 70px;
  display: flex;
  align-items: center;
}

.box_icon {
  color: #24cce0;
  font-size: 30px;
}

.box_text {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #FFF;
}

.bottom-link_msg {
  max-width: 50px;
}

@media only screen and (max-width: 768px) {
  .header_psm {
    /* display: flex;
    align-items: center;
    justify-content: start; */
    width: 100%;
  }

  .header_psm .backIcon {
    cursor: pointer;
    width: 25px;
  }

  .header_psm .pet_img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .header_psm h3 {
    font-size: 16px;
    display: inline;
  }

  .para {
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #616161;
  }

  .box {
    /* position: relative;
    top: 270px;
    margin: 10px; */
  }

  .box_row {
    background: #F5813C;
    border: 0.5px solid #F5813C;
    box-sizing: border-box;
    border-radius: 10px;
    height: 60px;
    display: flex;
    align-items: center;
  }

  .box_icon {
    color: #24cce0;
    font-size: 25px;
  }

  .box_text {
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.02em;
    color: #FFF;
    display: block;
    text-align: center;
    /* padding-right: 3rem; */
  }

  .bottom-link_msg {
    min-width: 72px;
  }
}

@media only screen and (max-width: 315px) {
  .box_text {
    display: block;
    text-align: center;
    /* padding-right: 0; */
  }

  .bottom-link_msg {
    min-width: initial;
  }
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #24cce0;
  border-color: #24cce0;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.recaptcha-wrapper {
  width: 100% !important;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

/* .g-recaptcha {
  width: 100% !important;
} */
