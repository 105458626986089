.view_product > div {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px;
}

.view_product > div h6 {
  font-weight: 700;
}

.view_product > div h6 + span {
  color: #ffb631;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.view_product > div h6 + span > span {
  color: #616161;
}

.view_product > div > .product_img {
  display: flex;
  margin: 10px auto 20px;
}

.view_product > div > .product_img img {
  margin: 0 auto;
  width: 180px;
  height: auto;
}

.view_product > div .Collapsible__trigger {
  padding: 0;
  position: static;
}
.view_product > div .collapse_name {
  display: inline-flex;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-left: 0;
}

.view_product > div .legal svg {
  float: right;
  margin-top: 8px;
}

.view_product > div .legal svg path {
  fill: #acacac;
}

.view_product > div .is-closed > svg {
  transition: transform 0.3s ease-in-out;
  margin-right: 10px;
}

.view_product > div .is-open > svg {
  transition: transform 0.3s ease-in-out;
  margin-right: 10px;
}

.view_product > div .collapse_inner_content {
  padding: 0;
  border: none;
}

.view_product > div .profile_collapse_row {
  padding: 1px 0;
}

.view_product > div .profile_collapse_row.desc {
  margin-top: 24px;
  display: block;
}

.view_product > div .profile_collapse_row.desc span {
  padding: 0;
}

.view_product > div .profile_collapse_row_title + span {
  padding-left: 22px;
}

.product_pet_profile_list {
  margin-left: 15px;
  font-family: Helvetica;
}

.product_pet_profile_list h6 {
  font-weight: 700;
}

.product_pet_profile_list ul {
  margin-bottom: 20px;
}

.product_pet_profile_list > div {
  margin-bottom: 134px;
}

.product_pet_profile_list ul div:nth-child(1),
.product_pet_profile_list > div div:nth-child(1) {
  width: 40px;
  height: 40px;
}

.product_pet_profile_list ul div:nth-child(2),
.product_pet_profile_list > div div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 10px;
}

.product_pet_profile_list ul div:nth-child(2) img,
.product_pet_profile_list > div div:nth-child(2) img {
  transform: rotate(180deg);
  width: 22px;
  opacity: 30%;
}

.product_pet_profile_list ul li,
.product_pet_profile_list > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
