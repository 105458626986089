.header_pvd {
    /* display: flex;
    align-items: center;
    justify-content: start; */
    width: 100%;
}

.header_pvd .backIcon {
    cursor: pointer;
    width: 50px;
}

.contentInCenter {
    margin-top: 15px;
}

.header_pvd .pet_img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
}

.header_pvd h3 {
    font-size: 22px;
    font-weight: bold;
    display: inline;
}

.share_icon_pvd img {
    margin-top: 10px;
    width: 50px;
}

.all-page-container {
    height: 450px;
    overflow: auto;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 10px;
}

@media only screen and (max-width: 768px) {
    .header_pvd {
        /* display: flex;
        align-items: center;
        justify-content: start; */
        width: 100%;
    }

    .header_pvd .backIcon {
        cursor: pointer;
        width: 25px;
    }

    .header_pvd .pet_img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 5px;
    }

    .header_pvd h3 {
        font-size: 16px;
        display: inline;
    }

    .share_icon_pvd img {
        width: 35px;
        margin-top: 0;
    }

}