.header_pdi {
    display: flex;
    align-items: center;
    justify-content: start;
}

.header_pdi .backIcon_pdi {
    cursor: pointer;
    width: 50px;
}

.header_pdi .pet_img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
}

.header_pdi h3 {
    font-size: 18px;
    font-weight: bold;
}

.para {
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #616161;
}

.share_icon_pdi img {
    width: 50px;
    margin-top: 10px;
}

.exclIcon_pdi {
    width: 40px;
}

@media only screen and (max-width: 768px) {
    .header_pdi {
        /* display: flex;
        align-items: center;
        justify-content: start; */
        width: 100%;
    }

    .header_pdi .backIcon_pdi {
        cursor: pointer;
        width: 25px;
    }

    .header_pdi .pet_img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 5px;
    }

    .header_pdi h3 {
        font-size: 16px;
    }

    .para {
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #616161;
    }

    .share_icon_pdi img {
        width: 30px;
        margin-top: 0;
    }

    .exclIcon_pdi {
        width: 30px;
    }

}