.header_prp {
    /* display: flex;
    align-items: center;
    justify-content: start; */
    width: 100%;
}

.header_prp .backIcon_ppr {
    cursor: pointer;
    width: 50px;
}

.header_prp .pet_img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
}

.header_prp h3 {
    font-size: 22px;
    font-weight: bold;
    display: inline !important;
}

.list-group {
    border-radius: 18px !important;
}

/* .list-group {
    border: 1px solid #E5E5E5 !important;
} */

.list-group-item:first-child {
    border-radius: 10px 10px 0 0 !important;
}

.list-group-item:last-child {
    border-radius: 0 0 10px 10px !important;
}

.left_text {
    font-family: Helvetica, sans-serif;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #000;
}

.right_text {
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #295E65;
    border: none;
    background-color: #fff;
}

.right_text:focus {
    border: none !important;
    outline: none;
}

.para {
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #616161;
}

.exclIcon_ppr {
    width: 40px;
}

.box_row {
    background: #F5813C;
    border: 0.5px solid #F5813C;
    box-sizing: border-box;
    border-radius: 10px;
    height: 70px;
    display: flex;
    align-items: center;
    margin: auto !important;
}

.box_icon {
    color: #24CCE0;
    font-size: 30px;
}

.box_text_prp {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #295E65;
}

@media only screen and (max-width: 768px) {
    .header_prp {
        /* display: flex;
        align-items: center;
        justify-content: start; */
        width: 100%;
    }

    .header_prp .backIcon_ppr {
        cursor: pointer;
        width: 25px;
    }

    .header_prp .pet_img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        /* margin-left: 3px; */
        margin-right: 5px;
    }

    .header_prp h3 {
        font-size: 16px;
        font-weight: bold;
        display: inline !important;
    }

    .left_text {
        font-family: Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.02em;
        color: #000;
    }

    .right_text {
        font-family: Helvetica, sans-serif;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #295E65;
    }

    .para {
        font-family: Helvetica, sans-serif;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #616161;
    }

    .exclIcon_ppr {
        width: 30px;
    }

    /* .box {
        position: relative;
        top: 300px;
        margin: 10px;
    } */

    .box_row {
        background: #F5813C;
        border: 0.5px solid #F5813C;
        box-sizing: border-box;
        border-radius: 10px;
        height: 60px;
        display: flex;
        align-items: center;
        margin: auto !important;
    }

    .box_icon {
        color: #24CCE0;
        font-size: 25px;
    }

    .box_text_prp {
        font-family: Helvetica;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.02em;
        color: #FFFFFF;
    }
}