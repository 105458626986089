.header_pvp {
  /* display: flex;
    align-items: center;
    justify-content: start; */
  width: 100;
}

.header_pvp .backIcon {
  cursor: pointer;
  width: 50px;
}

.header_pvp .pet_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

.header_pvp h3 {
  font-size: 22px;
  font-weight: bold;
  display: inline;
}

.parent_host {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #616161 !important;
}

.para {
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #616161;
}

.exclIcon_pvp {
  width: 40px;
}

.person_link {
  overflow-x: hidden;
  font-family: Helvetica, sans-serif;
  width: 100% !important;
  height: 60px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.person_link img {
  background: #f4fcfd;
}

.gps_pvp {
  width: 50px;
  border-radius: 50%;
}

.msg_pvp {
  width: 30px;
}

.parent__name {
  font-weight: 550;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #000000;
}

.bottom-link {
  max-width: 85px;
}

@media only screen and (max-width: 768px) {
  .header_pvp {
    /* display: flex;
        align-items: center;
        justify-content: start; */
    width: 100%;
  }

  .header_pvp .backIcon {
    cursor: pointer;
    width: 25px;
  }

  .header_pvp .pet_img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
  }

  .header_pvp h3 {
    font-size: 16px;
    display: inline;
  }

  .para {
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #616161;
  }

  .exclIcon_pvp {
    width: 30px;
  }

  .gps_pvp {
    width: 40px;
    border-radius: 50%;
  }

  .msg_pvp {
    width: 23px;
  }

  .bottom-link {
    min-width: 72px;
  }
}
